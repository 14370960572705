
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CompsetModel from '@/modules/compsets/models/compset.model';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';

@Component({
    components: { CustomSelect },
})
export default class CompsetDocumentFilter extends Vue {
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    @Prop({ type: Boolean, default: false })
    disable!: boolean;

    @Prop({ type: Boolean, default: false })
    skeleton!: boolean;

    get compsetId() {
        return this.documentFiltersService.compsetId;
    }

    set compsetId(value) {
        if (!value) return;
        this.documentFiltersService.updateCompset(value);
    }

    currentCompset(compsetId: string) {
        const compset = this.compsetsService.getCompset(compsetId);
        if (!compset) {
            return null;
        }
        return compset.name;
    }

    get compsetTypeItems(): Item[] {
        if (!this.compsetsService.compsets) {
            return [];
        }

        return this.compsetsService.compsets
            .filter(c => c.ownerHotelId === Number(this.$route.params.hotelId))
            .map((compset: CompsetModel) => ({
                value: compset.id,
                name: `${compset.name} (${this.$t(`compset.${compset.type}`)})`,
            }));
    }
}
