
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import MarketsFiltersService, { MarketsFiltersServiceS } from '@/modules/markets/markets-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

@Component({
    components: { CustomSelect },
})
export default class ProviderTypeFilter extends Vue {
    @inject(MarketsFiltersServiceS)
    private marketsFiltersService!: MarketsFiltersService;

    @inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    get provider() {
        return this.marketsFiltersService.currentProvider;
    }
    set provider(value) {
        this.marketsFiltersService.saveProvider(value);
    }

    get providerItems(): Item[] {
        return this.providersService
            .toItemsList(this.providersService.marketProviders);
    }
}
