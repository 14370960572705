
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ScanBtn from '@/modules/common/components/ui-kit/scan-btn.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import MarketsService, { MarketsServiceS } from '../markets.service';
import SCAN_STATUS from '../constants/scan-status.constant';

@Component({ components: { ScanBtn } })
export default class MarketsScan extends Vue {
    @inject(MarketsServiceS) private marketsService!: MarketsService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(OpenTelemetryServiceS) private openTelemetryService!: OpenTelemetryService;

    @Prop({
        type: String,
    })
    private provider!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public skeleton!: boolean;

    get isScanning() {
        return this.marketsService.scanStatus(this.provider) === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        return !this.marketsService.isScanAvailable();
    }

    get lastScanDate() {
        return this.marketsService.finishScanDate(this.provider);
    }

    get title() {
        return this.disabled && this.documentFiltersService.isPreviousMonth ? 'Not allowed to scan previous months' : '';
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }
        this.$gtag.event('on-demand', {
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });

        const settings = {
            provider: this.provider,
            compsetId: this.documentFiltersService.settings.compsetId,
            los: this.documentFiltersService.settings.los,
            pos: this.documentFiltersService.settings.pos,
        };

        await this.marketsService.triggerScan(settings);
    }
}
